import React from 'react';
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import Card from "react-md/lib/Cards/Card";
import CardTitle from "react-md/lib/Cards/CardTitle";
import CardText from "react-md/lib/Cards/CardText";
import Media from "react-md/lib/Media/Media";
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../../layout';

const Nutrition = () => (
  <StaticQuery
    query={graphql`
      query {
        Image: file(relativePath: { eq: "nutrition.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Grid stacked>
          <Cell
            align='middle'
            desktopSize={8}
            tabletSize={6}
          >
            <Media>
              <Img fluid={data.Image.childImageSharp.fluid} />
            </Media>
          </Cell>
          <Cell
            align='middle'
            desktopSize={8}
            tabletSize={6}
          >
            <Card>
              <CardTitle title="Nutrition at Mindful Montessori" />
              <CardText>
                <p className="md-text-justify">
                  We are passionate about the quality of food we serve our children and
                  good nutrition is a major part of life at our Center.
                  Like you, we want the children in our care to receive all the
                  vital nutrients they need to grow healthily.
                  Our Chef provides all our meals, the ingredients of which are
                  sourced from local suppliers. Hot meals are cooked every day in
                  a hygienic kitchen with our supervision, encouraging the children
                  to try and experience various flavours and textures.
                </p>
                <p className="md-text-justify">
                  <b>Family-style dining</b>
                </p>
                <p className="md-text-justify">
                  It is an endeared practice followed for mealtimes in an Early
                  childhood Montessori learning environment. Family-style dining
                  offers a great opportunity for children to work together to
                  prepare for mealtimes, serve their meals and enjoy mealtime
                  conversations with each other, as well as assist in the clean-up process.
                </p>
                <p className="md-text-justify">
                  <b>Lunch Meal</b>
                </p>
                <p className="md-text-justify">
                  Our meals are nutritiously balanced to cover all of the major
                  food groups, and all meals are cooked fresh on a four-weekly menu rota.
                </p>
                <p className="md-text-justify">
                  <b>Snacks</b>
                </p>
                <p className="md-text-justify">
                  Fresh seasonal fruit and salad are provided throughout the day
                  alongside fresh drinking water and milk. Crucially, we discourage
                  sugary food and drinks at Mindful Montessori and are fully
                  committed to promoting children’s health and well-being.
                </p>
                <p className="md-text-justify">
                  <b>Allergies and dietary requirements</b>
                </p>
                <p className="md-text-justify">
                  If your child has any food allergies, we will of course make
                  sure they are provided with alternative options from the menu,
                  and any specific dietary requirements are managed for each child.
                </p>
              </CardText>
            </Card>
          </Cell>
        </Grid>
      </Layout>
    )}
  />
)

export default Nutrition;
